html {
  --color-links-main: #15769c;
  --color-background-users: #eee;
  --color-overlay-text: #fff;
  --color-header-main: #455fac;

  font-size: 16px;
  // font-family: "Lato", Arial, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Lato", Helvetica, Arial, sans-serif;

    p {
      margin-top: 0;
      line-height: 1.4;
    }

    pre {
      padding: 0.75rem;
      margin: 0;
      background: #fffed3;
      border-radius: 0.25rem;
      font-size: 0.75rem;
      font-family: "Nanum Gothic Coding", monospace;
      line-height: 1rem;
      overflow-y: scroll;
    }

    hr {
      border: 0;
      border-top: 1px dotted #eee;
    }

    label {
      margin-bottom: 1rem;
    }

    .error {
      background: #ffee99;
      font-size: 0.875rem;
      padding: 1rem;
      margin: 1rem;
    }

    .success {
      background: #d1f3fa;
      font-size: 0.875rem;
      padding: 1rem;
      margin: 1rem;
    }

    .hyphenate {
      -webkit-hyphens: auto;
      hyphens: auto;
    }
    .uc {
      text-transform: capitalize;
    }
    .ws-nw {
      white-space: nowrap;
    }

    .cur-p {
      cursor: pointer;
    }
    .hov:hover {
      background-color: #fff;
      filter: drop-shadow(0 0.05rem 2rem #00003350);
    }

    .no-max-w {
      max-width: none;
    }
    .fw-b {
      font-weight: bold;
    }
    .word-break {
      word-break: break-word;
    }
    .of-h {
      overflow: hidden;
    }
    .of-w {
      overflow-wrap: break-word;
    }
    .of-y-s {
      overflow-y: scroll;
    }

    /* Background */
    .bg-pink-glamour {
      background-color: #ff7675;
      color: #fff;
    }
    .bg-chi-gong {
      background-color: #d63031;
      color: #fff;
    }
    .bg-first-date {
      background-color: #fab1a0;
    }
    .bg-orangeville {
      background-color: #e17055;
      color: #fff;
    }
    .bg-sour-lemon {
      background-color: #ffeaa7;
    }
    .bg-bright-yarrow {
      background-color: #fdcb6e;
    }
    .bg-light-green {
      background-color: #55efc4;
    }
    .bg-mint-leaf {
      background-color: #00b894;
      color: #fff;
    }
    .bg-faded-poster {
      background-color: #81ecec;
    }
    .bg-robins-egg {
      background-color: #00cec9;
      color: #fff;
    }
    .bg-darner-tail {
      background-color: #74b9ff;
    }
    .bg-electron-blue {
      background-color: #0984e3;
      color: #fff;
    }
    .bg-shy-moment {
      background-color: #a29bfe;
      color: #fff;
    }
    .bg-exodus-fruit {
      background-color: #6c5ce7;
      color: #fff;
    }
    .bg-american-river {
      background-color: #636e72;
      color: #fff;
    }
    .bg-dracula-orchid {
      background-color: #2d3436;
      color: #fff;
    }
    .bg-extra-extra-light-grey {
      background-color: #f4f4f4;
    }
    .bg-extra-light-grey {
      background-color: #eee;
    }
    .bg-light-grey {
      background-color: #bbb;
    }
    .bg-grey {
      background-color: #bbb;
    }
    .bg-ice-grey {
      background-color: #def;
    }
    .bg-blue {
      background-color: #1e78ff;
      color: #fff;
    }
    .bg-orange {
      background-color: #f90;
      color: #fff;
    }
    .bg-white {
      background-color: #fff;
      color: #333;
    }

    /* Colors */
    .tc-pink-glamour {
      color: #ff7675;
    }
    .tc-chi-gong {
      color: #d63031;
    }
    .tc-first-date {
      color: #fab1a0;
    }
    .tc-orangeville {
      color: #e17055;
    }
    .tc-sour-lemon {
      color: #ffeaa7;
    }
    .tc-bright-yarrow {
      color: #fdcb6e;
    }
    .tc-light-green {
      color: #55efc4;
    }
    .tc-mint-leaf {
      color: #00b894;
    }
    .tc-faded-poster {
      color: #81ecec;
    }
    .tc-robins-egg {
      color: #00cec9;
    }
    .tc-darner-tail {
      color: #74b9ff;
    }
    .tc-electron-blue {
      color: #0984e3;
    }
    .tc-shy-moment {
      color: #a29bfe;
    }
    .tc-exodus-fruit {
      color: #6c5ce7;
    }
    .tc-american-river {
      color: #636e72;
    }
    .tc-dracula-orchid {
      color: #2d3436;
    }
    .tc-light-grey {
      color: #bbb;
    }
    .tc-grey {
      color: #888;
    }
    .tc-dark-grey {
      color: #444;
    }
    .tc-blue {
      color: #1e78ff;
    }
    .tc-orange {
      color: #f90;
    }
    .tc-white {
      color: #fff;
    }

    /* Border */
    .bb-dotted {
      border-bottom: 1px dotted #eee;
    }

    /* Font Sizes */
    .fs-xs {
      font-size: 0.7rem;
    }
    .fs-s {
      font-size: 0.875rem;
    }
    .fs-m {
      font-size: 1rem;
    }
    .fs-ml {
      font-size: 1.2rem;
    }
    .fs-l {
      font-size: 1.4rem;
    }
    .fs-xl {
      font-size: 2rem;
    }

    /* Margin Top */
    .mt-none {
      margin-top: 0;
    }
    .mt-xxs {
      margin-top: 0.35rem;
    }
    .mt-xs {
      margin-top: 0.5rem;
    }
    .mt-s {
      margin-top: 1rem;
    }
    .mt-m {
      margin-top: 2rem;
    }
    .mt-l {
      margin-top: 3rem;
    }
    .mt-xl {
      margin-top: 4rem;
    }

    /* Margin Bottom */
    .mb-none {
      margin-bottom: 0;
    }
    .mb-xxs {
      margin-bottom: 0.35rem;
    }
    .mb-xs {
      margin-bottom: 0.5rem;
    }
    .mb-s {
      margin-bottom: 1rem;
    }
    .mb-m {
      margin-bottom: 2rem;
    }
    .mb-l {
      margin-bottom: 3rem;
    }
    .mb-xl {
      margin-bottom: 4rem;
    }

    /* Margin Right */
    .ml-a {
      margin-left: auto;
    }
    .ml-xxs {
      margin-left: 0.35rem;
    }
    .ml-xs {
      margin-left: 0.5rem;
    }
    .ml-s {
      margin-left: 1rem;
    }
    .ml-m {
      margin-left: 2rem;
    }
    .ml-l {
      margin-left: 3rem;
    }
    .ml-xl {
      margin-left: 4rem;
    }

    /* Margin Right */
    .mr-a {
      margin-right: auto;
    }
    .mr-xxs {
      margin-right: 0.35rem;
    }
    .mr-xs {
      margin-right: 0.5rem;
    }
    .mr-s {
      margin-right: 1rem;
    }
    .mr-m {
      margin-right: 2rem;
    }
    .mr-l {
      margin-right: 3rem;
    }
    .mr-xl {
      margin-right: 4rem;
    }

    /* Padding Top */
    .pt-xxs {
      padding-top: 0.25rem;
    }
    .pt-xs {
      padding-top: 0.5rem;
    }
    .pt-s {
      padding-top: 1rem;
    }
    .pt-m {
      padding-top: 2rem;
    }
    .pt-l {
      padding-top: 3rem;
    }
    .pt-xl {
      padding-top: 4rem;
    }

    /* Padding Bottom */
    .pb-xxs {
      padding-bottom: 0.25rem;
    }
    .pb-xs {
      padding-bottom: 0.5rem;
    }
    .pb-s {
      padding-bottom: 1rem;
    }
    .pb-m {
      padding-bottom: 2rem;
    }
    .pb-l {
      padding-bottom: 3rem;
    }
    .pb-xl {
      padding-bottom: 4rem;
    }

    /* padding left */
    .pl-none {
      padding-left: 0;
    }
    .pl-xs {
      padding-left: 0.5rem;
    }
    .pl-s {
      padding-left: 1rem;
    }
    .pl-m {
      padding-left: 2rem;
    }
    .pl-l {
      padding-left: 3rem;
    }
    .pl-xl {
      padding-left: 4rem;
    }

    /* padding right */
    .pr-none {
      padding-right: 0;
    }
    .pr-xs {
      padding-right: 0.5rem;
    }
    .pr-s {
      padding-right: 1rem;
    }
    .pr-m {
      padding-right: 2rem;
    }
    .pr-l {
      padding-right: 3rem;
    }
    .pr-xl {
      padding-right: 4rem;
    }

    /* Alignment */
    .al-l {
      text-align: left;
    }
    .al-c {
      text-align: center;
    }
    .al-r {
      text-align: right;
    }

    /* Width */
    .width-3rem {
      width: 3rem;
    }
    .width-4rem {
      width: 4rem;
    }
    .width-5rem {
      width: 5rem;
    }
    .width-10rem {
      width: 10rem;
    }
    .width-100 {
      width: 100%;
    }
    .width-max-md {
      max-width: 800px;
      margin: 0 auto;
    }
    .width-max-73rem {
      max-width: 73rem;
    }
    .width-max-75rem {
      max-width: 75rem;
    }
    .height-100 {
      height: 100%;
    }
    .height-m {
      height: 1.5rem;
    }
    .height-l {
      height: 3.5rem;
    }
    .height-min-4 {
      min-height: 4rem;
    }
    .height-min-8 {
      min-height: 8rem;
    }
    .height-min-12 {
      min-height: 12rem;
    }
    .height-max-20 {
      max-height: 20rem;
    }

    .display-block {
      display: block;
    }
    .display-none {
      display: none;
    }
    .visible-n {
      visibility: hidden;
    }

    /* Display Flex */
    .flex-row-t {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    .flex-row-c {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .flex-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .flex-wrap-y {
      flex-wrap: wrap;
    }
    .flex-grow-n {
      flex-grow: 0;
    }
    .flex-grow-y {
      flex-grow: 1;
    }
    .flex-shrink-n {
      flex-shrink: 0;
    }
    .flex-shrink-y {
      flex-shrink: 1;
    }
    .flex-wrap-y {
      flex-wrap: wrap;
    }
    .flex-wrap-n {
      flex-wrap: nowrap;
    }
    .flex-justify-start {
      justify-content: flex-start;
    }
    .flex-justify-end {
      justify-content: flex-end;
    }

    /* Positions */
    .pos-a {
      position: absolute;
    }
    .pos-r {
      position: relative;
    }
    .pos-f {
      position: fixed;
    }

    /* Priorities */
    .task-urgent {
      color: #f50057;
    }
    .task-high {
      color: #f60;
    }
    // .task-medium { color: #8ac; }

    .br-25 {
      border-radius: 0.25rem;
    }
    /* Border Priority */
    .bl-urgent {
      border-top: 0.25rem solid #f50057;
    }
    .bl-high {
      border-top: 0.25rem solid #f90;
    }
    .bl-medium {
      border-top: 0.25rem solid #ffd900;
    }
    .bl-low {
      border-top: 0.25rem solid #8ac;
    }
    /* Border Priority */
    .bl-completed-urgent {
      border-top: 0.25rem solid #888;
    }
    .bl-completed-high {
      border-top: 0.25rem solid #aaa;
    }
    .bl-completed-medium {
      border-top: 0.25rem solid #ccc;
    }
    .bl-completed-low {
      border-top: 0.25rem solid #eee;
    }

    /* Z-Index */
    .z-100 {
      z-index: 1000;
    }

    .main {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }
    .background1,
    .background2,
    .background3 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      max-width: none;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-x: hidden;
      overflow-y: scroll;
      filter: grayscale(10%);
    }

    .background1 {
      background: url("/images/background1.png") center center / cover no-repeat
        transparent;
    }

    .background2 {
      background: url("/images/background2.png") center center / cover no-repeat
        transparent;
    }

    .background3 {
      background: #fff;
      //   background: url("/images/background3.png") center center / cover no-repeat
      //     transparent;
    }

    .capsule {
      border-radius: 1rem;
      padding: 0 0.5rem;
    }

    #root {
      background-color: #fff;
      display: flex;
      max-width: none;

      .logo {
        flex-grow: 1;

        a {
          display: flex;
          width: 9rem;
          height: auto;

          img {
            width: 100%;
          }
        }
      }

      .promo-header {
        background-color: var(--color-header-main);
        color: #fff;
      }

      .img-fill {
        width: 100%;
        height: auto;
      }
    }

    .main-navigation {
      padding-top: 1rem;
      width: 280px;
      overflow-x: hidden;
    }

    .drawer-header {
      background-color: var(--color-header-main);
      display: flex;
      flex-direction: row;
      padding-left: 1rem;

      .logo {
        margin: 0.5rem 0 0;
        flex-grow: 2;

        a img {
          width: 9rem;
        }
      }

      button {
        color: #fff;
        // margin-left: -12px;
      }
    }

    .drawer {
      min-width: 250px;
      padding: 0;

      .drawer-items {
        padding: 0 0.5rem 0.5rem;
      }
    }

    @media screen and (max-width: 667px) {
      .mobile-hide {
        display: none;
      }
    }
  }
}
